import Vue from "vue";
import moment from "moment";
import {translate, isEnglish, isFrench} from "./i18n";

Vue.filter(`tr`, translate);

export function pluralize(text) {
    let translated = translate(text);
    if (translated) {
        return `${translated}s`;
    } else {
        return translated;
    }
}

Vue.filter(`pluralize`, pluralize);

Vue.filter(`plural`, function(count, word) {
    if (parseFloat(count) > 1 && word.slice(-1) !== `s` && word.slice(-1) !== `x`) {
        return word + `s`;
    } else {
        return word;
    }
});

export function removeTz(value) {
    if (!value)
        return value;
    return value.replace(/\.\d{3}Z/g, ``);
}

Vue.filter(`removeTz`, removeTz);

Vue.filter(`reverse`, function(value) {
    // slice to make a copy of array, then reverse the copy
    return value.slice().reverse();
});

Vue.filter(`yesNo`, function(value) {
    // slice to make a copy of array, then reverse the copy
    return value ? translate(`Yes|Oui`) : translate(`No|Non`);
});

export function getCurrency(currency) {
    if (!currency)
        currency = localStorage.getItem(`currency`);

    if (currency && [`CAD`, `USD`, `EUR`].indexOf(currency) > -1) {
        return currency;
    } else {
        return `CAD`;
    }
}

export function positiveSign(value) {
    if (value > 0) {
        return `+${value}`;
    } else {
        return value;
    }
}

Vue.filter(`positiveSign`, positiveSign);

export function upperCase(value) {
    if (value && typeof value === `string`) {
        return value.toUpperCase();
    } else {
        return value;
    }
}

Vue.filter(`upperCase`, upperCase);

export function price(value, currency) {
    if (isNullOrUndefined(value) || isNaN(value))
        return `-`;

    // replaced exactMath with native implementation, use vue-math-filters if you need it
    let price = Number(value).toFixed(2);

    switch (getCurrency(currency)) {
        case `EUR`:
            return `${price} €`;
        case `USD`:
            return `USD$ ${price}`;
        default:
            return isEnglish() ? `CAD$ ${price}` : `${price} $CAD`;
    }
}

Vue.filter(`price`, price);

export function priceShort(value, currency) {
    if (isNullOrUndefined(value) || isNaN(value))
        return `-`;

    // replaced exactMath with native implementation, use vue-math-filters if you need it
    let price = Number(value).toFixed(2);

    switch (getCurrency(currency)) {
        case `EUR`:
            return `${price}€`;
        case `USD`:
            return `$${price}`;
        default:
            return isEnglish() ? `$${price}` : `${price}$`;
    }
}

Vue.filter(`priceShort`, priceShort);

export function priceInCents(value, currency) {
    if (isNullOrUndefined(value) || isNaN(value))
        return `-`;
    let price = (value / 100).toFixed(2);

    switch (getCurrency(currency)) {
        case `EUR`:
            return `${price} €`;
        case `USD`:
            return `USD$ ${price}`;
        default:
            return isEnglish() ? `CAD$ ${price}` : `${price} $CAD`;
    }
}

Vue.filter(`priceInCents`, priceInCents);

export function smartPrice(value, currency) {
    if (value === 0 || value === `0`)
        return `-`;
    // Same formatting than Excel
    if (value < 0) {
        return `(${priceShort(Math.abs(value), currency)})`;
    } else {
        return priceShort(value, currency);
    }
}

Vue.filter(`smartPrice`, smartPrice);

export function priceRounded(value, currency) {
    if (isNullOrUndefined(value) || isNaN(value))
        return `-`;
    let price = Math.round(value).toLocaleString();

    switch (getCurrency(currency)) {
        case `EUR`:
            return `${price}€`;
        case `USD`:
            return `$${price}`;
        default:
            return isEnglish() ? `$${price}` : `${price}$`;
    }
}

Vue.filter(`priceRounded`, priceRounded);

export function date(value, format) {
    if (!value)
        return `-`;
    if (!format)
        return moment(value).format(`ll`);
    return moment(value).format(format);
}

Vue.filter(`date`, date);

export function longDate(value, format) {
    if (!value)
        return `-`;
    if (!format)
        return moment(value).format(`dddd LL`);
    return moment(value).format(format);
}

Vue.filter(`longDate`, longDate);

export function datetime(value, format) {
    if (!value)
        return `-`;
    if (!format)
        return moment(value).format(`lll`);
    return moment(value).format(format);
}

Vue.filter(`datetime`, datetime);

export function dateAgo(value) {
    if (!value)
        return `-`;
    return moment(value).fromNow();
}

Vue.filter(`dateAgo`, dateAgo);

export function time(value, format) {
    if (!value)
        return `-`;
    if (!format)
        return moment(value).format(`LT`);
    return moment(value).format(format);
}

Vue.filter(`time`, time);

export function timeToNow(value) {
    if (!value)
        return `-`;
    return moment(value).fromNow();
}

Vue.filter(`timeToNow`, timeToNow);

export function hour(value) {
    if (value === 0) {
        return `00:00`;
    }
    if (!value)
        return `-`;
    const rounded = Math.floor(value);
    let result = fillIntegerWithZero(rounded) + `:`;
    if (value - rounded === 0) {
        result += `00`;
    } else {
        result += Math.round((value - rounded) * 60);
    }
    return result;
}

Vue.filter(`hour`, hour);

export function dayOfWeekIndex(index) {
    const DaysOfWeekEN = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
    const DaysOfWeekFR = [`Dimanche`, `Lundi`, `Mardi`, `Mercredi`, `Jeudi`, `Vendredi`, `Samedi`];

    if (isFrench()) {
        return DaysOfWeekFR[index];
    } else {
        return DaysOfWeekEN[index];
    }
}

Vue.filter(`dayOfWeekIndex`, dayOfWeek);

export function dayOfWeek(value) {
    const DaysOfWeekEN = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
    const DaysOfWeekFR = [`Dimanche`, `Lundi`, `Mardi`, `Mercredi`, `Jeudi`, `Vendredi`, `Samedi`];

    if (isEnglish()) {
        return value;
    } else if (DaysOfWeekEN.indexOf(value) > -1) {
        return DaysOfWeekFR[DaysOfWeekEN.indexOf(value)];
    } else {
        return value;
    }
}

Vue.filter(`dayOfWeek`, dayOfWeek);

export function shortDayOfWeek(value) {
    value = dayOfWeek(value);
    if (!value || value.length < 3) {
        return value;
    }
    return value.substr(0, 3);
}

Vue.filter(`shortDayOfWeek`, shortDayOfWeek);

export function formatFileSize(bytes) {
    let aMultiples = [`B`, `KB`, `MB`, `GB`, `TB`, `PB`, `EB`, `ZB`, `YB`];
    let shiftResult = 0;
    for (let i = 0; i < aMultiples.length; ++i) {
        shiftResult = bytes / Math.pow(1000, i);
        if (shiftResult >= 1 && shiftResult < 1000) {
            return shiftResult.toFixed(2) + ` ` + aMultiples[i];
        }
    }
    return ``;
}

Vue.filter(`fileSize`, formatFileSize);

export function fileSizeMB(megaBytes) {
    return formatFileSize(megaBytes * 1000 * 1000);
}

Vue.filter(`fileSizeMB`, fileSizeMB);

export function phoneNumber(phoneNumber) {
    if (!phoneNumber)
        return ``;
    let result = phoneNumber.toString();
    result = result.replace(/\+(\d{0,2})(\d{3})(\d{3})(\d{4})/, `+$1 ($2) $3-$4`);
    result = result.replace(/(\d{0,2})(\d{3})(\d{3})(\d{4})/, `+$1 ($2) $3-$4`);
    result = result.replace(/(\d{3})(\d{3})(\d{4})/, `($1) $2-$3`);
    return result;
}

Vue.filter(`phoneNumber`, function(value) {
    if (!value)
        return ``;
    return phoneNumber(value);
});

export function postalCode(postalCode) {
    if (!postalCode)
        return ``;
    if (parseInt(postalCode) >= 1) { // Only Numbers US or FR format
        return postalCode;
    } else if (/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(postalCode)) {
        // Canadian Postal Code AXAXAX -> Add Space
        return `${postalCode.substring(0, 3)} ${postalCode.substring(3, 6)}`
    }
    return postalCode;
}

Vue.filter(`postalCode`, postalCode);

export function formatName(user) {
    if (user) {
        return user.first_name + ` ` + user.last_name;
    } else {
        return ``;
    }
}

Vue.filter(`userName`, formatName);

export function shortUserName({ first_name: firstName, last_name: lastName }) {
    if (typeof firstName !== `string`)
        return ``;

    if (typeof lastName !== `string`)
        return firstName;

    return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
}

Vue.filter(`shortName`, shortUserName);

export function familyName(name) {
    if (isStringEmpty(name)) {
        return ``;
    }
    if (isFrench()) {
        return `Famille ` + name;
    } else {
        return name + ` family`;
    }
}

Vue.filter(`familyName`, familyName);

export function formatNumber(value) {
    if (getValueOrDefault(value, 0) === 0 || value === `0`) {
        return 0;
    } else {
        if (isNaN(parseFloat(value))) {
            return value;
        } else {
            return parseFloat(value).toLocaleString();
        }
    }
}

Vue.filter(`number`, function(value) {
    if (isNullOrUndefined(value))
        return `-`;
    return formatNumber(+value);
});

Vue.filter(`unitBits`, function(value) {
    if (isStringEmpty(value))
        return `-`;

    if (value > 1000 * 1000 * 1000) {
        return `${Math.round(value / (100 * 1000 * 1000)) / 10} Gb`;
    } else if (value > 1000 * 1000) {
        return `${Math.round(value / (100 * 1000)) / 10} Mb`;
    } else {
        return `${Math.round(value / (100)) / 10} kb`;
    }
});

Vue.filter(`unitMb`, function(value) {
    if (isStringEmpty(value))
        return `-`;

    const Gb = Math.round(value / (100)) / 10;
    if (Gb < 1) {
        return `${Math.round(value)} Mb`;
    } else {
        return `${Gb} Gb`;
    }
});

Vue.filter(`unitBitsPerSec`, function(value) {
    if (isStringEmpty(value))
        return `-`;

    if (value > 1000 * 1000 * 1000) {
        return `${Math.round(value / (100 * 1000 * 1000)) / 10} Gb/s`;
    } else if (value > 1000 * 1000) {
        return `${Math.round(value / (100 * 1000)) / 10} Mb/s`;
    } else {
        return `${Math.round(value / (100)) / 10} kb/s`;
    }
});

Vue.filter(`unitKbPerSec`, function(value) {
    if (isStringEmpty(value))
        return `-`;

    if (value > 1000 * 1000) {
        return `${Math.round(value / (100 * 1000)) / 10} Gb/s`;
    } else if (value > 1000) {
        return `${Math.round(value / (100)) / 10} Mb/s`;
    } else {
        return `${Math.round(value)} kb/s`;
    }
});

export function percentage(value) {
    // Parse Float again to avoid Scientific stupid precision
    return parseFloat((parseFloat(value) * 100).toPrecision(2)) + `%`;
}

Vue.filter(`percentage`, function(value) {
    if (isNullOrUndefined(value))
        return `-`;

    return percentage(value);
});

Vue.filter(`round`, function(value) {
    if (isNullOrUndefined(value))
        return `-`;
    return Math.round(+value);
});

export function humanizeMinutes(value) {
    if (!value) {
        return `-`;
    }
    if (value > 60) {
        return `${parseInt(value / 60)} h ${fillIntegerWithZero(value % 60)}`;
    } else {
        return `${value} min`;
    }
}

Vue.filter(`humanizeMinutes`, humanizeMinutes);

export function humanizeSeconds(value) {
    if (!value) {
        return `-`;
    }
    if (value > 3600) {
        return `${parseInt(value / 3600)} h ${fillIntegerWithZero(Math.round(value / 60) % 60)}`;
    } else if (value > 60) {
        return `${parseInt(value / 60)} min ${fillIntegerWithZero(Math.round(value % 60))}`;
    } else {
        return `${value} secs`;
    }
}

Vue.filter(`humanizeSeconds`, humanizeSeconds);

/**
 * Format Meters distances in Km or Miles
 * @param value {Number} distance in meters
 * @returns {string}
 */
export function formatMeterDistance(value) {
    if (!value) {
        return `-`;
    }
    return translate(`${Math.round(value / 1609.34)} mi|${Math.round(value / 1000)} km`);
}

Vue.filter(`formatMeterDistance`, formatMeterDistance);

/**
 * Translate between meters or feet based on the language
 * @param value {Number} distance in meters
 * @returns {string|*|string|string}
 */
export function translateMeterDistance(value) {
    if (!value) {
        return `-`;
    }
    return translate(`${Math.round(value * 3.28084)}ft|${Math.round(value)}m`);
}

Vue.filter(`translateMeterDistance`, translateMeterDistance);

export function address(restaurant) {
    if (!restaurant) {
        return ``;
    }
    let address = ``;
    if (restaurant.address) {
        address += `${restaurant.address}`;
    }
    if (restaurant.city) {
        address += `, ${restaurant.city}`;
    }
    if (restaurant.postal_code) {
        address += `, ${restaurant.postal_code}`;
    }
    return address;
}

Vue.filter(`address`, address);

export function socialNetwork(socialNetworkId) {
    if (!socialNetworkId) {
        return ``;
    }
    switch (parseInt(socialNetworkId)) {
        case 0:
            return translate(`None|Aucun`);
        case 1:
            return `Facebook`;
        case 2:
            return `Eye-In`;
        case 3:
            return `Linkedin`;
        case 4:
            return `Google`;
        case 5:
            return `Twitter`;
    }
}

Vue.filter(`socialNetwork`, socialNetwork);

export function genderFilter(gender) {
    if (!gender) {
        return ``;
    }
    switch (gender) {
        case `male`:
            return translate(`Male|Homme`);
        case `female`:
            return translate(`Female|Femme`);
        case `other`:
            return translate(`Other|Autre`);
        default:
            return translate(`Not specified|Non spécifié`)
    }
}

Vue.filter(`gender`, genderFilter);

export default {};
