<template>
    <div class="app-page" :class="{ 'fullscreen': fullscreen}">
        <slot name="page-header"></slot>
        <component :is="noContainer ? 'div' : 'b-container'">
            <slot></slot>
        </component>

        <b-navbar v-if="!isLive()" class="env-bar" fixed="bottom" :variant="isLocal() ? 'info' : 'success'">
            <b-container>
                <div class="text-center w-100">
                    You are on <b-badge variant="light" class="env-name text-uppercase">{{getEnv()}}</b-badge> environment
                </div>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>

export default {
    name: `app-page`,
    props: {
        fullscreen: {type: Boolean},
        noContainer: {type: Boolean}
    }
}
</script>

<style lang="scss" scoped>
.app-page {
    background: linear-gradient(180deg,#f1f5f9,#edf8f2);

    &.fullscreen {
        width: 100vw;
        height: 100vh;
    }
}

.env-bar {
    color: white;
    font-size: 13pt;
    background: #00bcd4;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
    background-size: 20px 20px;

    .env-name {
        padding: 5px 10px;
        font-size: 100%;
    }
}

</style>
