<template>
    <div id="app">
        <keep-alive>
            <router-view :key="$route.fullPath"/>
        </keep-alive>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import "assets/style/global.scss";

  #app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
