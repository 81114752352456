import Vue from 'vue';
import Vuex from 'vuex';
import Network from "../vue-components/helpers/Network.js";
import router from "@/router.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null
    },
    getters: {
        isAuthenticated(state) {
            return !!state.user;
        }
    },
    mutations: {
        authSuccess(state, user) {
            state.user = user;
        },
    },
    actions: {
        getCurrentUser({commit}) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem(`token`);

                if (!token) {
                    const currentURL = new URL(document.URL);
                    const currentURLParams = new URLSearchParams(currentURL.search);

                    if (currentURLParams.has(`token`)) {
                        token = currentURLParams.get(`token`);
                    }
                    window.history.pushState({}, document.title, `/`);
                }

                if (token) {
                    Network.setToken(token);

                    Network.get(`/users/current-user`)
                        .then(response => {
                            const user = response.data;
                            if (user) {
                                commit(`authSuccess`, user);
                                resolve(user);
                            }
                        })
                        .catch(err => {
                            /*
                            To avoid being disconnected when server restart when working locally
                             */
                            if (window.env.VUE_APP_ENV !== `local`) {
                                localStorage.removeItem(`token`);
                                Network.removeToken();
                            }
                            reject(err);
                        });
                } else {
                    resolve(false);
                }
            });
        },
        logout() {
            localStorage.removeItem(`token`);
            Network.removeToken();
            router.push(`/`)
        }
    },
    modules: {}
});
