<template>
    <div class="login-with-google-container">
        <template v-if="!small">
            <div id="g_id_onload" data-client_id="263127917827-c81c1qgp7o97qsa9t1p8aj7ou320vkp8" data-callback="onGoogleLoginSuccess" data-auto_prompt="false"></div>
            <div class="g_id_signin alone" data-type="standard" data-size="large" data-theme="outline" data-text="sign_in_with" data-shape="rectangular" data-logo_alignment="left"></div>
        </template>
        <template v-else>
            <div id="g_id_onload" data-client_id="263127917827-c81c1qgp7o97qsa9t1p8aj7ou320vkp8" data-callback="onGoogleLoginSuccess" data-auto_prompt="false"></div>
            <b-btn id="login-with-google" v-b-tooltip variant="default" class="login-btn login-icon-btn">
                <i class="fab fa-google fa-fw"></i>
                <div class="g_id_signin" data-type="icon" data-size="large" data-theme="filled_blue" data-text="sign_in_with" data-shape="rectangular" width="60"></div>
            </b-btn>
        </template>
    </div>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `LoginGoogle`,
    props: {
        small: {type: Boolean, default: false}
    },
    data() {
        return {}
    },
    async mounted() {
        window.onGoogleLoginSuccess = googleObject => {
            this.signIn(googleObject.credential);
        }

        await this.insertScript()
    },
    methods: {
        async insertScript() {
            if (document.getElementById(`google-script`)) {
                this.scriptLoaded = true
                return
            }

            return new Promise(resolve => {
                const script = document.createElement(`script`)
                script.id = `google-script`
                script.src = `https://accounts.google.com/gsi/client`
                script.async = true;
                script.defer = true;
                script.onload = () => {
                    resolve()
                }

                document.body.appendChild(script)
            })
        },
        signIn(googleJWT) {
            Network.post(`/users/login-with-google`, {google_token: googleJWT})
                .then(async resp => {
                    this.$emit(`user`, resp.data)
                })
                .catch(error => {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion non valide`),
                        text: error.response.data
                    });
                })
        }
    }
}


</script>

<style lang="scss" scoped>
.login-with-google-container {
    cursor: pointer;
    user-select: none;
    border-radius: 50px;
    color: #ffffff;
    text-transform: initial;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 12pt;
    background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
    padding: 6px 0;
    border: 0;
    display: inline-block;
    transition: 150ms;
    position: relative;
    margin: 5px;

    ::v-deep .g_id_signin:not(.alone) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        iframe {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin: 0 !important;
            width: 60px !important;
            height: 47px !important;
        }
    }

    ::v-deep .g_id_signin.alone iframe {
        margin: auto !important;
    }

    #login-with-google {
        cursor: pointer;

        .fab {
            position: relative;
            z-index: 2;
            pointer-events: none;
            color: white;
        }

        &.login-icon-btn {
            display: inline-block;
            width: auto;
            border-radius: 3px;
            margin: 0 5px;

            .fab,
            .fas {
                font-size: 20px !important;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            cursor: pointer;

            border-radius: 3px;
            background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
            pointer-events: none;

            &:hover {
                background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
            }

            &:active {
                background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
            }
        }
    }
}

</style>
