import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import "../vue-components/helpers/vue-mixin.js";

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import VueSwal from 'vue-swal'
import moment from 'moment';
import VueMoment from 'vue-moment'
import appPage from '/src/layout/app-page.vue'

import '../vue-components/helpers/vue-filters.js';
import "../vue-components/helpers/vue-directives.js";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSwal);
Vue.use(VueMoment, {
    moment
});

Vue.component(`app-page`, appPage);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount(`#app`);
