import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store.js";

import home from './views/home.vue';
import changePassword from "@/views/change-password.vue";
import resetPassword from "@/views/reset-password.vue";
import loginAs from "@/views/login-as.vue";
import testPage from "./views/test-page.vue";

Vue.use(VueRouter);

function ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
        next();
    } else {
        store.dispatch(`getCurrentUser`)
            .then(() => {
                if (store.getters.isAuthenticated) {
                    next();
                } else {
                    next(`/`);
                }
            });
    }
}


const routes = [
    {
        path: `/`,
        name: `home`,
        component: home,
        alias: `/home`
    },
    {
        path: `/change-password/:optional?`,
        name: `change-password`,
        component: changePassword,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/reset-password/:username/:guid`,
        name: `reset-password`,
        component: resetPassword
    },
    {
        path: `/login-as`,
        name: `login-as`,
        component: loginAs,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/test-page`,
        name: `test-page`,
        component: testPage,
        beforeEnter: ifAuthenticated
    }
];

const router = new VueRouter({
    mode: `history`,
    routes: routes
});

export default router;
